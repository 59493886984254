// extracted by mini-css-extract-plugin
export const wrapper = "posts-module--wrapper--6YL2H";
export const searchBar = "posts-module--searchBar--128Tc";
export const singlePostWrapper = "posts-module--singlePostWrapper--3C4GC";
export const slider_main = "posts-module--slider_main--V5mor";
export const iconText = "posts-module--iconText--1NRvc";
export const paginationWrapper = "posts-module--paginationWrapper--WQEHm";
export const currentPageNumber = "posts-module--currentPageNumber--17aLC";
export const paginationNumber = "posts-module--paginationNumber--3OJfn";
export const nextPageClassName = "posts-module--nextPageClassName--3lGYy";
export const nextPrev = "posts-module--nextPrev--3x0zA";
export const disabledButton = "posts-module--disabledButton--1jxqj";